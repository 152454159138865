import { useEffect, useMemo, useState } from "react";

import { createClient, ContentfulClientApi } from "contentful";
import {
  IGeneralFields,
  IPostersFields,
} from "../../@types/generated/contentful";
// import axios from "axios";

// type StatusType = { statusText: string; icon: string };

export default function useContentfull() {
  const [Client, setClient] = useState<ContentfulClientApi>();

  const [general, setGeneral] = useState<IGeneralFields>();
  const [posters, setPosters] = useState<IPostersFields[]>([]);

  useEffect(() => {
    setClient(
      createClient({
        // This is the space ID. A space is like a project folder in Contentful terms
        space: "sqrnh770lkrc",
        // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
        accessToken: "qKivLuIvEB4fL3OS-XOPdn3i1mQ30NG5fyd-BnxKfCQ",
      })
    );
  }, []);

  useEffect(() => {
    if (Client) {
      getGeneral(Client).then(setGeneral);
      getPosters(Client).then(setPosters);
    }
  }, [Client]);

  return useMemo(() => {
    return { general, posters };
  }, [general, posters]);
}

async function getGeneral(Client: ContentfulClientApi) {
  return await Client.getEntries<IGeneralFields>({
    content_type: "general",
  }).then((response) => {
    return response.items.map((item) => item.fields)[0];
    // console.log(response.items.map((item) => item.fields));
  });
}
async function getPosters(Client: ContentfulClientApi) {
  return await Client.getEntries<IPostersFields>({
    content_type: "posters",
  }).then((response) => {
    return response.items.map((item) => item.fields);
    // console.log(response.items.map((item) => item.fields));
  });
}
