/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Button from "@mui/material/Button";
import main_logo from "../assets/icons/clipbait_logo.svg";
// import ReactPlayer from "react-player";
import PlainTypography from "@mui/material/Typography";
import { useContext, useEffect, useState } from "react";
import { AnimateSharedLayout, AnimatePresence, motion } from "framer-motion";
import { useTheme } from "styled-components";
import Fab from "@mui/material/Fab";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { ContentfullContext } from "../Contexts/Contentfull";
import { IPostersFields } from "../../@types/generated/contentful";
import Player from "@vimeo/player";
import { IconButton } from "@mui/material";
/* import IconButton from "@mui/material/IconButton";
import { DefaultContext } from "../Contexts/DefaultContext";
import { useInView } from "react-intersection-observer";
import useScrollSnap from "react-use-scroll-snap";
import { useTheme } from "styled-components"; */
import ReactPlayer from "react-player";
const Typography = motion(PlainTypography);

export function Home() {
  useEffect(() => {
    window.location.hash &&
      document.querySelector(window.location.hash)?.scrollIntoView();
  }, []);

  return (
    <div
      css={css`
        overflow-x: hidden;
      `}
    >
      <Header />
      <About />
      <Services />
      <Tech />
      <AnimationShowcase />
      <Breakdowns />
      <TempSection />
      <JoinUs />
      <Contact />
    </div>
  );
}

function AnimationShowcase() {
  return (
    <div id="animation">
      <Typography
        css={css`
          max-width: 1100px;
          margin: 0 auto;
          padding: 0 1.8rem;
          padding-top: 2.5rem;
          @media (max-width: 850px) {
            font-size: 2rem;
          }
        `}
        color="primary"
        // animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
        variant="h3"
      >
        2D Animation | Motion graphics Reel
      </Typography>
      <div
        css={css`
          padding: 1.8rem;
          display: flex;
          margin: 0 auto;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          max-width: 1100px;
          #breakdown {
            min-height: 65vh;
            @media (max-width: 850px) {
              min-height: 20vh;
              height: 200px !important;
              /* min-height: 25vh; */
              /* height: 200px; */
            }
          }
        `}
      >
        <ReactPlayer
          // minHeight="65vh"
          width="100%"
          controls={!false}
          id="breakdown"
          url="https://vimeo.com/845199803"
        />
      </div>
    </div>
  );
}
function Breakdowns() {
  return (
    <div>
      <Typography
        css={css`
          max-width: 1100px;
          margin: 0 auto;
          padding: 0 1.8rem;
          padding-top: 2.5rem;
          @media (max-width: 850px) {
            font-size: 2rem;
          }
        `}
        color="primary"
        // animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
        variant="h3"
      >
        BTS | Breakdowns
      </Typography>
      <div
        css={css`
          padding: 1.8rem;
          display: flex;
          margin: 0 auto;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          max-width: 1100px;
          #breakdown {
            min-height: 65vh;
            @media (max-width: 850px) {
              min-height: 20vh;
              height: 200px !important;
              /* min-height: 25vh; */
              /* height: 200px; */
            }
          }
        `}
      >
        <ReactPlayer
          // minHeight="65vh"

          width="100%"
          id="breakdown"
          controls={!false}
          url="https://vimeo.com/854279642"
        />
      </div>
    </div>
  );
}
function TempSection() {
  return (
    <div id="tribute">
      <Typography
        css={css`
          max-width: 1100px;
          margin: 0 auto;
          padding: 0 1.8rem;
          padding-top: 2.5rem;
          @media (max-width: 850px) {
            font-size: 2rem;
          }
        `}
        color="primary"
        variant="h3"
      >
        Tribute to Indian Advertising
      </Typography>
      <div
        css={css`
          padding: 1.8rem;
          display: flex;
          margin: 0 auto;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          max-width: 1100px;
          #breakdown {
            min-height: 65vh;
            @media (max-width: 850px) {
              min-height: 20vh;
              height: 200px !important;
              /* min-height: 25vh; */
              /* height: 200px; */
            }
          }
        `}
      >
        <ReactPlayer
          // minHeight="65vh"
          controls={!false}
          width="100%"
          id="breakdown"
          url="https://vimeo.com/854280827"
        />
      </div>
      <div
        css={css`
          max-width: 1100px;
          margin: 0 auto;
          padding: 0 1.8rem;
          padding-top: 0;
          @media (max-width: 850px) {
            font-size: 2rem;
          }
        `}
      >
        <Typography variant="h4">FEVIKWIK - FISHING AD</Typography>
        <Typography variant="subtitle1" textAlign="justify">
          Who hasn’t heard of, or not chuckled at, Fevicol’s advertising? The
          brand is clearly such a dominant leader in its category that its
          competition is hardly visible or even recalled. After becoming the
          no.1 adhesive choice of all carpenters in Tier 1 markets in India,
          when Fevicol wanted to expand its reach to newer markets, it brought
          onboard one of the best advertising agencies in India, to create a
          series of ads. These ads appealed to every Indian, regardless of their
          background, and they communicated the message of their products very
          clearly. The brand awareness and utility value of Fevicol products
          began spreading across the entire country, and eventually Fevicol
          became a dominant leader in its category nationwide. Here we present
          to you one of their iconic ads, in photo-realistic 3D animation style,
          of one of their products called ‘Fevikwik’, which released in March
          1998. While presenting the idea to the Pidilite team, as a proof of
          concept, the great Piyush Pandey actually dropped a coin in a glass of
          water, applied Fevikwik at one end of a pencil and picked up the coin
          with the pencil from inside the glass! The film was directed by
          another stalwart, Prasoon Pandey, whose other strength being casting,
          got two brilliant actors to put this idea into action – Kamal Chopra
          (Englishman) and Rajesh Khera (South Indian). This was an ad which
          connected with the audience in a “Chutki”, and even today is
          considered to be one of the best Indian advertisements.
        </Typography>
      </div>
    </div>
  );
}

function Header() {
  const [watch] = useState(true);

  /* useEffect(() => {
    const t = setTimeout(() => {
      setWatch(true);
    }, 2000);
    return () => {
      clearTimeout(t);
    };
  }, []); */

  return (
    <AnimateSharedLayout type="crossfade">
      <AnimatePresence>
        {watch ? (
          <>
            <Nav />
            <Reel />
          </>
        ) : (
          <Initial />
        )}
      </AnimatePresence>
    </AnimateSharedLayout>
  );
}

function Initial() {
  // const theme = useTheme();
  return (
    <motion.div
      css={css`
        position: relative;
      `}
    >
      <motion.img
        layout
        layoutId="main_logo"
        css={css`
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 200px;
          filter: brightness(1.6) saturate(1.4);
        `}
        src={main_logo}
        alt=""
      />
      <motion.div
        // layoutId="show_reel"
        css={css`
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          z-index: -1;

          overflow: hidden;
          opacity: 0.2;
        `}
      >
        <motion.iframe
          css={css`
            width: 100vw;
            height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
            min-height: 100vh;
            min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          `}
          src="https://player.vimeo.com/video/845198584?h=a35df5280e&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;muted=1&amp;autoplay=1&amp;autopause=0&amp;controls=0&amp;loop=1&amp;app_id=122963"
          frameBorder="0"
          allow="autoplay; picture-in-picture"
          title="Clipbaitmedia - CG 2"
          data-ready="true"
          id="show_reel"
        />
        {/*<iframe
        src="https://player.vimeo.com/video/653356514?h=a35df5280e"
        width="640"
        height="360"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe> */}
        {/* <div
          css={css`
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 0);
            padding-bottom: 20px;
            @media (max-width: 850px) {
              display: none;
            }
          `}
        >
          <IconButton
            onClick={() => {
              document.querySelector("#show_reel")?.requestFullscreen();
            }}
          >
            <FullscreenIcon />
          </IconButton>
        </div> */}
      </motion.div>
    </motion.div>
  );
}
const MotionFab = motion(Fab);

function Nav() {
  const theme = useTheme();
  const [background, setBackground] = useState("#0000");
  const [bottomNav, setBottomNav] = useState(false);
  const [small, setSmall] = useState(false);
  useEffect(() => {
    const onscroll = () => {
      if (window.scrollY > 50) {
        setSmall(true);
        setBackground(theme.colors.main.background);
        return;
      }
      if (window.scrollY < 1) {
        setSmall(false);
      }
      setBackground("#0000");
    };
    window.addEventListener("scroll", onscroll);
    return () => {
      window.removeEventListener("scroll", onscroll);
    };
  }, [theme]);

  /* useEffect(() => {
    console.log(background);
  }, [background]); */

  return (
    <>
      <motion.div
        css={css`
          display: none;
          @media (max-width: 850px) {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 2rem;
          }
        `}
      >
        <motion.img
          layoutId="main_logo"
          layout
          animate={{ width: small ? "100px" : "200px" }}
          css={css`
            width: 200px;
            filter: ${theme.colors.main.type === "dark"
              ? "brightness(500%) saturate(0);"
              : "brightness(0%) saturate(0);"};
          `}
          src={main_logo}
          alt=""
        />
      </motion.div>
      <motion.nav
        initial={{ opacity: 0, background: "#0000" }}
        animate={{
          background: background,
          opacity: 1,
          /* padding: !small ? "1.8rem 2rem" : "0.8rem 1.8rem",
          fontSize: !small ? "1.2rem" : "1rem", */
        }}
        css={css`
          background: transparent;
          position: fixed;
          width: 100%;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: 10;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: ${!small ? "1.8rem 2rem" : "0.8rem 1.8rem"};
          font-size: ${!small ? "1.2rem" : "1rem"};
          @media (max-width: 850px) {
            /* left: 0;
            transform: none;
            position: sticky; */
            display: none;
          }
        `}
      >
        <div
          css={css`
            width: 100%;
            max-width: 1100px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 850px) {
              justify-content: center;
            }
          `}
        >
          <motion.img
            animate={{ width: small ? "100px" : "200px" }}
            css={css`
              width: 200px;
              filter: ${theme.colors.main.type === "dark"
                ? "brightness(500%) saturate(0);"
                : "brightness(0%) saturate(0);"};
            `}
            src={main_logo}
            alt=""
          />
          <motion.ul
            css={css`
              display: flex;
              gap: 1rem;
              @media (max-width: 850px) {
                display: none;
              }
            `}
          >
            <Button
              css={css`
                font-size: ${small ? "0.6rem" : "1.2rem"};
                transition: all 0.2s ease-in-out;
              `}
              href="#about"
              color="secondary"
            >
              About us
            </Button>
            <Button
              css={css`
                font-size: ${small ? "0.6rem" : "1.2rem"};
                transition: all 0.2s ease-in-out;
              `}
              href="#services"
              color="secondary"
            >
              Services
            </Button>
            <Button
              css={css`
                font-size: ${small ? "0.6rem" : "1.2rem"};
                transition: all 0.2s ease-in-out;
              `}
              href="#tools"
              color="secondary"
            >
              Tools
            </Button>
            <Button
              css={css`
                font-size: ${small ? "0.6rem" : "1.2rem"};
                transition: all 0.2s ease-in-out;
              `}
              href="#joinus"
              color="secondary"
            >
              Join us
            </Button>
            <Button
              css={css`
                font-size: ${small ? "0.6rem" : "1.2rem"};
                transition: all 0.2s ease-in-out;
              `}
              href="#contact"
              color="secondary"
            >
              Contact us
            </Button>
          </motion.ul>
        </div>
      </motion.nav>
      <motion.nav
        animate={{ opacity: !small ? 0 : 1 }}
        css={css`
          position: fixed;
          bottom: -1px;
          left: 0;
          width: 100%;
          display: none;

          @media (max-width: 850px) {
            display: flex;
          }
          flex-direction: column;
          justify-content: center;

          z-index: 100;
        `}
      >
        <MotionFab
          drag="x"
          dragConstraints={{ left: 120 - window.innerWidth, right: 0 }}
          css={{
            marginRight: "2rem",
            marginLeft: "auto",
            backgroundColor: theme.colors.main.accent,
            "&:hover": {
              backgroundColor: theme.colors.main.accent,
            },
          }}
          onClick={() => setBottomNav((s) => !s)}
        >
          {bottomNav ? (
            <CloseIcon color="inherit" />
          ) : (
            <MenuIcon color="inherit" />
          )}
        </MotionFab>
        <motion.ul
          onClick={() => setBottomNav(false)}
          animate={{
            height: bottomNav ? "auto" : 0,
            opacity: bottomNav ? 1 : 0,
            pointerEvents: bottomNav ? "all" : "none",
          }}
          css={css`
            padding: 0.6rem 1rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: ${theme.colors.main.background};
            box-shadow: 0 0 10px 10px ${theme.colors.main.background};
          `}
        >
          <Button href="#about" color="secondary">
            About us
          </Button>
          <Button href="#services" color="secondary">
            Services
          </Button>
          <Button href="#tools" color="secondary">
            Tools
          </Button>
          <Button href="#contact" color="secondary">
            Contact us
          </Button>
        </motion.ul>
      </motion.nav>
    </>
  );
}
function Reel() {
  // const animation = useAnimation();
  return (
    <motion.div
      // layoutId="show_reel"
      css={css`
        /* position: relative;
        overflow: hidden;
        width: auto;
        height: 100vh;
        */
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        /* z-index: -1; */

        overflow: hidden;
        @media (max-width: 850px) {
          height: auto;
          display: flex;
          flex-direction: column;
          align-items: stretch;
        }
      `}
    >
      <ReactPlayer
        // minHeight="65vh"
        css={css`
          width: 100vw;
          height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
          min-height: 100vh;
          min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          @media (max-width: 850px) {
            min-width: 0; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
            min-height: 0;
            top: 0;
            left: 0;
            transform: none;
            width: auto;
            position: static;
            height: 56.25vw;
          }
        `}
        autoplay
        config={{
          vimeo: {
            playerOptions: {
              background: true,
              controls: false,
              loop: true,
              autoplay: true,
              muted: true,
              playsinline: true,
            },
          },
        }}
        controls={!false}
        width="100%"
        id="reel"
        url="https://vimeo.com/845198584"
      />
      {/*<iframe
        src="https://player.vimeo.com/video/653356514?h=a35df5280e"
        width="640"
        height="360"
        frameborder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
      ></iframe> */}
      <div
        css={css`
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          padding-bottom: 20px;
          z-index: 100;
          @media (max-width: 850px) {
            display: none;
          }
        `}
      >
        <IconButton
          onClick={() => {
            document.querySelector("#show_reel")?.requestFullscreen();
          }}
        >
          <FullscreenIcon />
        </IconButton>
      </div>
    </motion.div>
  );
}

// <motion.iframe
//   ref={(ref) => {
//     if (ref) {
//       const iframePlayer = new Player(ref);
//       iframePlayer.ready().then(() => iframePlayer.play());
//     }
//     /* if (ref && window.innerWidth < 850) {
//       const iframePlayer = new Player(ref);
//       iframePlayer.on("play", (err) => {
//         setTimeout(() => {
//           animation.start({ scale: 1 });
//         }, 1500);
//       });
//     } */
//   }}
//   css={css`
//     width: 100vw;
//     height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
//     min-height: 100vh;
//     min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     @media (max-width: 850px) {
//       min-width: 0; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
//       min-height: 0;
//       top: 0;
//       left: 0;
//       transform: none;
//       width: auto;
//       position: static;
//       height: 56.25vw;
//     }
//   `}
//   src="https://player.vimeo.com/video/845198584?h=a35df5280e&amp;title=0&amp;byline=0&amp;portrait=0&amp;playsinline=0&amp;muted=1&amp;autoplay=1&amp;autopause=0&amp;background=1&amp;controls=1"
//   frameBorder="0"
//   allow="autoplay; picture-in-picture"
//   title="Clipbaitmedia - CG 2"
//   data-ready="true"
//   id="show_reel"
// />
function Tech() {
  // const theme = useTheme();
  return (
    <div
      id="tools"
      css={css`
        /* flex-direction: column; */
      `}
    >
      <Typography
        css={css`
          max-width: 1100px;
          margin: 0 auto;
          padding: 0 1.8rem;
          padding-top: 2.5rem;
          @media (max-width: 850px) {
            font-size: 2rem;
          }
        `}
        color="primary"
        // animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
        variant="h3"
      >
        Tools
      </Typography>

      <div
        css={css`
          padding: 1.8rem;
          padding-top: 0;
          display: flex;
          margin: 0 auto;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          max-width: 1100px;
        `}
      >
        <div>
          <SoftwareChip
            img={
              <img
                color="white"
                src="https://img.icons8.com/material-outlined/50/ffffff/nuke.png"
                alt=""
              />
            }
            label="Nuke"
          />
          <SoftwareChip
            img={
              <img
                alt=""
                src="https://img.icons8.com/ios-glyphs/50/ffffff/autodesk-maya.png"
              />
            }
            label="Maya"
          />
        </div>
        <div>
          <SoftwareChip
            img={
              <img
                src="https://img.icons8.com/windows/50/ffffff/3ds-max.png"
                alt=""
              />
            }
            label="3DS Max"
          />
          <SoftwareChip
            img={
              <img
                src="https://img.icons8.com/material-outlined/50/ffffff/cinema-4d.png"
                alt=""
              />
            }
            label="Cinema 4D"
          />
        </div>
        <div>
          <SoftwareChip
            img={
              <img
                alt=""
                src="https://img.icons8.com/ios-filled/100/ffffff/adobe-logo.png"
              />
            }
            label="Adobe Suite"
          />

          <SoftwareChip
            img={
              <img
                src="https://img.icons8.com/material-sharp/50/ffffff/davinci-resolve.png"
                alt=""
              />
            }
            label="DaVinci Resolve"
          />
        </div>
      </div>
    </div>
  );
}
function SoftwareChip({
  img,
  label,
}: {
  img: React.ClassAttributes<HTMLImageElement>;
  label: string;
}) {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
        margin: 0.5rem;

        img {
          filter: ${theme.colors.main.type !== "dark" ? "invert(1)" : ""};
          width: 35px;
          height: 35px;
        }
      `}
    >
      {img}
      <Typography
        ml="10px"
        variant="h5"
        css={css`
          opacity: 0.85;
        `}
      >
        {label}
      </Typography>
    </div>
  );
}

function About() {
  const { general } = useContext(ContentfullContext);
  return (
    <div
      css={css`
        min-height: 60vh;
        padding: 0 1.6rem;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1100px;
        margin: 0 auto;
        @media (max-width: 850px) {
          padding-top: 2.5rem;
        }
      `}
      id="about"
    >
      <Typography
        css={css`
          @media (max-width: 850px) {
            font-size: 2rem;
          }
        `}
        // animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 100 }}
        variant="h3"
        color="primary"
      >
        About us
      </Typography>
      <Typography
        variant="body1"
        maxWidth="1100px"
        css={css`
          opacity: 0.8;
          text-align: justify;
          @media (max-width: 850px) {
            text-align: start;
          }
        `}
        marginTop="10px"
        lineHeight="2rem"
        fontSize="1.2rem"
      >
        {general
          ? general.about.split("\n").map((para: string) => (
            <>
              {para}
              <br />
            </>
          ))
          : ""}
      </Typography>
    </div>
  );
}

type ServiceType = {
  name: string;
  icon: string;
};

function Services() {
  const services: Partial<ServiceType>[] = [
    {
      name: "Concept Design",
      icon: "https://img.icons8.com/material-rounded/24/000000/design.png",
    },
    {
      name: "2D animation",
      icon: "https://img.icons8.com/material-outlined/24/000000/animation.png",
    },
    {
      name: "3D animation/CGI",
      icon: "https://img.icons8.com/material-outlined/24/000000/3d.png",
    },
    {
      name: "3D Modelling",
      icon: "https://img.icons8.com/ios-glyphs/60/000000/3d-scale--v1.png",
    },
    {
      name: "Compositing",
      icon: "https://img.icons8.com/material-rounded/24/000000/nuke.png",
    },
    {
      name: "Matte painting",
      icon: "https://img.icons8.com/material-sharp/24/000000/online-paint-tool.png",
    },
    {
      name: "Rotoscopy",
      icon: "https://img.icons8.com/material-sharp/24/000000/frame.png",
    },
    {
      name: "Green Screen",
      icon: "https://img.icons8.com/material-outlined/50/000000/screen-printing.png",
    },
    {
      name: "Motion Graphics",
      icon: "https://img.icons8.com/material-outlined/24/000000/grayscale.png",
    },
    {
      name: "Animatics",
      icon: "https://img.icons8.com/windows/30/000000/mesh.png",
    },
    {
      name: "Pre-visualisation",
      icon: "https://img.icons8.com/material-outlined/96/000000/visible--v1.png",
    },
    {
      name: "Look Development",
      icon: "https://img.icons8.com/material-rounded/24/000000/detective--v1.png",
    },
    {
      name: "Video Editing (upto 8K)",
      icon: "https://img.icons8.com/material-sharp/24/000000/video-skimming.png",
    },
    {
      name: "Color Grading",
      icon: "https://img.icons8.com/ios-glyphs/30/000000/color-filter.png",
    },
    {
      name: "360 Video (both in 2D & 3D)",
      icon: "https://img.icons8.com/material-two-tone/24/000000/360-view.png",
    },
    {
      name: "On-set Supervision",
      icon: "https://img.icons8.com/material-sharp/24/000000/training.png",
    },
  ];

  const newArr = [];
  while (services.length) newArr.push(services.splice(0, 4));

  return (
    <div
      id="services"
      css={css`
        padding: 0 1.6rem;
        padding-top: 2.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1100px;
        justify-content: center;
        margin: 0 auto;
        .services {
          max-width: 1100px;
          margin-left: -22px;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 10px;
        }
        .group-group {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
        }
        .service-group {
          flex-direction: column;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
        }
      `}
    >
      <Typography
        css={css`
          @media (max-width: 850px) {
            font-size: 2rem;
            max-width: 1100px;
          }
          width: 100%;
        `}
        variant="h3"
        color="primary"
      >
        Services
      </Typography>

      <div className="services">
        <div className="group-group">
          {newArr
            .map((service, i) => <ServiceGroup key={i} services={service} />)
            .slice(0, 2)}
        </div>
        <div className="group-group">
          {newArr
            .map((service, i) => <ServiceGroup key={i} services={service} />)
            .slice(2, 3)}
        </div>
        <div className="group-group">
          {newArr
            .map((service, i) => <ServiceGroup key={i} services={service} />)
            .slice(3, 4)}
        </div>
      </div>
    </div>
  );
}

function ServiceGroup({ services }: { services: Partial<ServiceType>[] }) {
  return (
    <div className="service-group">
      {services.map((service, i) =>
        service.name && service.icon ? (
          <ServiceChip key={i} service={service as ServiceType} />
        ) : (
          <div key={i}></div>
        )
      )}
    </div>
  );
}

function ServiceChip({ service }: { service: ServiceType }) {
  const theme = useTheme();
  return (
    <div
      // whileHover={{ borderBottom:`1px solid ${theme.colors.main.accent}`}}
      css={css`
        padding: 10px 20px;
        /* margin: 10px; */
        /* border-bottom: 1px solid ${theme.colors.main.accent}; */
        /* align-items: fle; */
        display: flex;
        align-items: center;
        transition: opacity 100ms ease-in-out;
        cursor: default;
      `}
    >
      <img
        src={service.icon}
        alt="img"
        css={css`
          width: 24px;
          height: 24px;
          object-fit: contain;
          margin-right: 8px;
          filter: ${theme.colors.main.type === "dark" ? "invert(1)" : ""};
        `}
      />
      <span
        css={css`
          opacity: 0.85;
        `}
      >
        {service.name}
      </span>
    </div>
  );
}

function JoinUs() {
  const { posters } = useContext(ContentfullContext);
  return (
    <div id="joinus">
      <div
        css={css`
          padding: 2rem;
          padding-top: 2.5rem;
          display: flex;
          flex-direction: column;
          /* align-items: center; */
          justify-content: center;
          max-width: 1100px;
          margin: 0 auto;
        `}
      >
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
            @media (max-width: 850px) {
              flex-wrap: wrap;
            }
            .posters {
              display: flex;
              gap: 1rem;

              @media (max-width: 850px) {
                flex-wrap: wrap;
              }
              img {
                width: 100%;
              }
            }
          `}
        >
          <div>
            <Typography
              css={css`
                width: 100%;
                @media (max-width: 850px) {
                  font-size: 2rem;
                }
              `}
              variant="h3"
              color="primary"
            >
              Join Us
            </Typography>
            <Typography
              variant="body1"
              css={css`
                opacity: 0.8;
              `}
            >
              We are always on the lookout for bright young enthusiastic talent
              who are looking to work in a dynamic environment. See yourself to
              be a fit?
            </Typography>
          </div>
          <div className="posters">
            {/* <ImageGallery
              showThumbnails={false}
              items={[
                ...posters.map((poster, i) => ({
                  original: poster.poster?.fields.file.url,
                })),
              ]}
            /> */}
            {posters.map((poster, i) => (
              <Poster key={i} poster={poster} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function Poster({ poster }: { poster: IPostersFields }) {
  return (
    <div>
      <img
        css={css`
          width: 400px;
        `}
        src={poster.poster?.fields.file.url}
        alt=""
      />
    </div>
  );
}

function Contact() {
  const { general } = useContext(ContentfullContext);
  const img = general ? general.map.fields.file.url : "";
  const theme = useTheme();
  return (
    <div
      css={css`
        /* background: url(${img}); */
        /* background-position: center; */
        /* background-attachment: fixed; */
        /* min-height: 60vh; */
        background-color: #0f1114;
      `}
      id="contact"
    >
      <div
        css={css`
          padding: 3rem 2rem;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          max-width: 1100px;
          margin: 0 auto;
        `}
      >
        <div
          css={css`
            width: 100%;
          `}
        >
          <Typography
            css={css`
              @media (max-width: 850px) {
                font-size: 2rem;
              }
            `}
            // ml="-0.5rem"
            variant="h3"
            color="primary"
          >
            Contact Us
          </Typography>
          <Typography variant="h6">Clipbait Media Pvt Ltd</Typography>
          <Typography
            variant="body1"
            css={css`
              opacity: 0.8;
            `}
          >
            <a
              css={{ color: theme.colors.main.text }}
              href="mailto:hello@clipbaitmedia.com"
            >
              hello@clipbaitmedia.com
            </a>
          </Typography>
          <Typography
            css={css`
              filter: saturate(1.2);
              /* opacity: 0.8; */
            `}
            variant="body1"
          >
            <a
              target={"_blank"}
              href="https://www.google.com/maps/place/Clipbait+Media+Pvt+Ltd/@13.0221584,77.598004,15z/data=!4m5!3m4!1s0x0:0x6e804805a1665fa2!8m2!3d13.0225112!4d77.5980993"
              rel="noreferrer"
            >
              228, Ground Floor, 14th A Cross,
              <br /> 2nd Block, RT Nagar, Bangalore - 560032 <br />
              GST : 29AAJCC8126F1ZI
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
}
