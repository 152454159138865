import { createContext, FC, useEffect, useState } from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import { ThemeProvider } from "styled-components";
import { DefaultContextProps } from "../types";

import { useThemer } from "../hooks/useThemer";
import { useWantCode } from "../hooks/useWantCode";

export const DefaultContext = createContext<DefaultContextProps>(
  {} as DefaultContextProps
);

export const DefaultContextProvider: FC = ({ children }) => {
  // custom hookss

  const themer = useThemer();
  const muitheme = createTheme({
    shape: {
      borderRadius: 0,
    },
    typography: {
      fontFamily: "'DM Sans', sans-serif;",
      h4: {
        fontSize: "1.5rem",
        fontWeight: "bold",
      },
    },
    palette: {
      mode: themer.theme.colors.main.type,
      primary: {
        main: themer.theme.colors.main.accent,
      },
      secondary: {
        main: themer.theme.colors.main.text,
      },
      info: {
        main: "#5E2E86",
      },
      background: {
        default: themer.theme.colors.main.background,
        paper: themer.theme.colors.main.background,
      },
    },
  });
  useWantCode(themer.theme);
  // useLoaderCleanup();
  /* useEffect(() => {
    themer.dispatch({ type: "lightmode" });
  }, [themer]); */
  const [debug, setDebug] = useState(false);
  useEffect(() => {
    const onkey = (e: KeyboardEvent) => {
      console.log(e);
      if ((e.shiftKey && e.key === "D") || (e.shiftKey && e.key === "d")) {
        setDebug((s) => !s);
      }
      if ((e.shiftKey && e.key === "E") || (e.shiftKey && e.key === "e")) {
        setDebug((s) => !s);
        document.designMode === "off"
          ? (document.designMode = "on")
          : (document.designMode = "off");
      }
    };
    window.addEventListener("keypress", onkey);

    return () => {
      return window.removeEventListener("keypress", onkey);
    };
  }, [themer]);

  return (
    <DefaultContext.Provider value={{ themer, debug }}>
      {themer.theme && (
        <ThemeProvider theme={themer.theme}>
          <MuiThemeProvider theme={muitheme}>{children}</MuiThemeProvider>
        </ThemeProvider>
      )}
    </DefaultContext.Provider>
  );
};
