/** @jsxImportSource @emotion/react */
// import { css } from "@emotion/react";
import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./components/Home";

const HomeConcept = lazy(() => import("./components/Home2"));

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/concept"
            element={
              <Suspense fallback={<div>Loading...</div>}>
                <HomeConcept />
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
